<template>
    <div>
        <v-container>
          <v-card style="background-color: #F5F5F5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                <span v-if="isNew">Nueva</span>
                <span v-if="!isNew">Editar</span>
                categorías de elemento
            </div>

            <v-form
              ref="form"
              v-model="isValid"
              autocomplete="off">
              <v-tabs v-model="tab" background-color="transparent" show-arrows>
                  <v-tabs-slider color="blue"></v-tabs-slider>

                  <v-tab href="#tab-cat">Categoría</v-tab>
                  <v-tab href="#tab-emp">Empresas</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" touchless>
                  <v-tab-item :value="'tab-cat'">
                      <v-card style="background-color: #F5F5F5" flat>
                          <!-- FORMULARIO CATEGORÍA -->
                          <v-row>
                              <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      v-model="elemCat.ElemCatCod"
                                      label="Código">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      v-model="elemCat.ElemCatNom"
                                      required
                                      :rules="[v => !!v || 'El nombre es obligatorio']"
                                      label="Nombre *">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                          <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                              <v-text-field
                                  class="mb-n7"
                                  outlined
                                  dense
                                  text
                                  :value="elemCat.ElemCatParent != null ? elemCat.ElemCatParent.ElemCatNom : ''"
                                  tabindex="-1"
                                  readonly
                                  label="Tipo">
                              </v-text-field>
                          </v-col>
                      </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="5">
                                  <v-textarea
                                      class="mt-0 mb-n7"
                                      outlined
                                      auto-grow
                                      v-model="elemCat.ElemCatObs"
                                      label="Observaciones">
                                  </v-textarea>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'tab-emp'" eager>
                      <v-card style="background-color: #F5F5F5" flat>
                          <!-- EMPRESAS -->
                          <v-row class="mt-0">
                              <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                  <EmpresasRelacionadas
                                      ref="relatedEmps"
                                      :empList="empDataList" 
                                      :empTotal="empDataTotal" 
                                      :loading="loading" 
                                      @update-data="updateEmpData">
                                  </EmpresasRelacionadas>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
              </v-tabs-items>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px">
                    Aceptar
                </v-btn>
                
                <v-btn
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                    @click="goToList">
                    Cancelar
                </v-btn>

                <!-- && canDelete" -->
                <v-btn v-if="!isNew"
                    color="error"
                    class="mt-2 mb-4"
                    style="width:100px"
                    @click="onBtnShowDeleteModal">
                    Borrar
                </v-btn>
            </v-row>
            </v-form>
          </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar categoría -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CATEGORÍA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la categoría? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
export default ({
    components: {
        EmpresasRelacionadas
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            elemCat: {
                ElemCatId: 0,
                ElemCatCod: '',
                ElemCatNom: '',
                ElemCatObs: '',
                ElemCatParent: null,
                ElemCatIdParent: null
            },
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: []
        }
    },
    // watch: {
    //   elemCat: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.elemCat.ElemCatId = this.$route.params.id;
        this.loadData();
      } else {
        // Init ElemCatParent
        if(this.$store.state.ElemCatParentData != null) {
          this.elemCat.ElemCatParent = this.$store.state.ElemCatParentData;
          this.elemCat.ElemCatIdParent = this.$store.state.ElemCatParentData.ElemCatId;
        }

        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/categorias-elemento');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_ELEMCAT',
            ElemCatId: this.elemCat.ElemCatId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elemcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.ElemCat;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la categoría.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        const empIds = this.empDataList.map(m => m.EmpId);

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            ElemCat: this.elemCat,
            EmpIds: empIds
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elemcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la categoría.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            ElemCatId: this.elemCat.ElemCatId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elemcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la categoría.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Categoría no encontrada.');
              this.goToList();
              return;
            }

            this.elemCat = data.item;
            
            this.empDataTotal = data.item.ElemCatEmp.length;
            this.empDataList = [];
            data.item.ElemCatEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });
          });
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.ElemCatId != null) {
              this.goToList();
            }
            else {
              alert('Error al guardar la categoría.');
            }
          });
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
          this.$refs.form.validate();
      }
      //#endregion
    } 
})
</script>
